<template>
	<footer class="
			tablet:mx-48
			desktop:mx-56 
			p-16
			tablet:px-0
			tablet:py-48
			desktop:py-56
			"
			:class="[footerClasses]"
			>
		<aside class="w-full">
			<div v-once class="
			flex
			flex-col
			space-y-24
			tablet:space-y-0
			tablet:flex-row-reverse
			tablet:items-start
			tablet:justify-between
			tabletWide:items-center
			desktop:mx-auto
			desktopWide:max-w-1328
			">
				<nav class=" flex space-x-24 items-center">
					<a href="https://www.facebook.com/gorentalsnz" rel="nofollow noopener" target="_blank" class="link link-goPink text-goGrayBright">
						<svg-icon name="facebook" title="Facebook" class="iconSize-32" />
					</a>
					<a href="https://www.instagram.com/gorentals.nz/" rel="nofollow noopener" target="_blank" class="link link-goPink text-goGrayBright">
						<svg-icon name="instagram" title="Instagram" class="iconSize-32" />
					</a>
					<a href="https://www.youtube.com/user/GORentalsNZCarRental" rel="nofollow noopener" target="_blank" class="link link-goPink text-goGrayBright">
						<svg-icon name="youtube" title="YouTube" class="iconSize-32" />
					</a>
					<a href="https://www.linkedin.com/company/go-rentals-new-zealand/" rel="nofollow noopener" target="_blank" class="link link-goPink text-goGrayBright">
						<svg-icon name="linkedin" title="LinkedIn" class="iconSize-32" />
					</a>
					<a href="https://www.weibo.com/gorentals" rel="nofollow noopener" target="_blank" class="link link-goPink text-goGrayBright">
						<svg-icon name="weibo" title="Weibo" class="iconSize-32" />
					</a>
					<button class="bg-transparent p-0 border-0 cursor-pointer link link-goPink text-goGrayBright" @click="openWeChatQRCode">
						<svg-icon name="wechat" title="WeChat" class="iconSize-32" />
					</button>
				</nav>

				<nav class="flex flex-col tabletWide:flex-row tabletWide:items-center space-y-8 tabletWide:space-y-0 tabletWide:space-x-24 ">
					<nuxt-link to="/privacy-cookie-policy/" class="link link-goPink text-goGray">Privacy & Cookie Policy</nuxt-link>
					<nuxt-link to="/terms/" class="link link-goPink text-goGray">Terms & Conditions</nuxt-link>
					<nuxt-link to="/about-us/work-for-us/" class="link link-goPink text-goGray">Careers</nuxt-link>
				</nav>
			</div>
		</aside>
	</footer>
</template>

<script>
export default {
	computed: {
		weChatQRCodeModal () {
			return import("~/components/modals/weChatQRCode.vue")
		},
		footerClasses () {
			if (this.$store.state.footerStyle === 'short') return 'border-t border-goGrayBright'
			return ''
		}
	},
	methods: {
		async openWeChatQRCode() {
			const weChatQRCodeModal = await this.weChatQRCodeModal
			this.$modalService.show(weChatQRCodeModal.default)
		},
	},
}
</script>
